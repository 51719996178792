import React, { useState, useEffect }  from "react"; 
import {Table } from 'react-bootstrap';
import http from '../_api/http';

const TransferCertificate = () => {
  
  const [loading, setLoading] = useState(true);
  const [transfercertificate, SetTransferCertificate] = useState([]);

  const PUBLIC_URL = process.env.REACT_APP_APIURL;

  useEffect(() => {
    // alert(process.env.REACT_APP_APIURL);
    http.get('api/transfercertificate/ApiTransferCertificateList').then((res) => {
        // console.log(res);
      // debugger
      if (res.data.status === 200) {
        // console.log(res.data.status);
        SetTransferCertificate(res.data.transfercertificate);
        //  console.log(res.data.requirement);
        setLoading(false);
      }
    })
  }, []);
  return (
    <>    
    <section className="contact-one dup">
        <div className="container">
        <Table striped bordered hover responsive>  
            <thead>  
              <tr>     
                <th>Name</th>  
                <th>Standard</th>
                <th>Date</th>
                <th>File</th>  
                <th>Download</th>  
              </tr>  
            </thead> 
            {loading
              ? <h4>View Transfer Certificate list...</h4>
              :
              <> 
              {transfercertificate.map((item, i) => 
              <tbody key={i}> 
                <tr>    
                  <td>{item.name}</td>  
                  <td>{item.standard}</td>
                  <td>{item.date}</td>
                  <td>{item.file}</td>
                  <td>
                        <div>
                            <a href={PUBLIC_URL + 'TransferFile/' + item.file} className="thm-btn" target="_blank">
                            <i class="fa fa-download" aria-hidden="true"></i>
                            </a>
                        </div>
                  </td>  
                </tr> 
            </tbody> 
             )}    
            </>
          }
       </Table>  
          <div className="result text-center"></div>
        </div>
    </section>
</>

  );
};

export default TransferCertificate;
